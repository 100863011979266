import React from 'react';
import Header from '../components/Header';
import Layout from '../components/layout';
import Adbutler from '../components/Adbutler';

const TyBCM = () => {
  // sets adbutler zone id's based on param data
  let adButlerIds = ['290069', '290070', '290071', '290072', '290073'];

  return (
    <>
      <Header />
      <Layout isfluid={false}>
        <Adbutler adButlerIds={adButlerIds} />
      </Layout>
    </>
  );
};

export default TyBCM;
